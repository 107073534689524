import Cookies from "js-cookie";
import { sha256 } from "js-sha256";
import md5 from "js-md5";


export class WeaverAPI {
  constructor(apiUrl, cookieDomain) {
    this.url = apiUrl;
    this.publicKey = "L4#9hF7bTR=7e332v";
    this.privateKey = "Bg72k5AN16";
    this.cookieDomain = cookieDomain;
  }

  getItemQuantity(callback) {
    const failure = (statusCode, responseText) => {
      throw `Wystąpił bład komunikacji z API: ${statusCode} : ${responseText}`;
    };

    const success = (responseText) => {
      let itemQuantity = 0;
      let items = [];
      let basketAmount = null;
      let basketCurrency = null;

      try {
        const response = JSON.parse(responseText);

        if (response !== null) {
          response["items"].forEach((element) => {
            itemQuantity += element["quantity"];
            let item = {
              id: element["id"],
              cartOfferId: element["cartOfferId"],
              title: element["product"]["name"],
              quantity: element["quantity"],
              price: element["price"]["strikedGross"]
                  ? element["price"]["strikedGross"]
                  : element["price"]["gross"],
              product: element["product"],
              currencyIsoCode: element["price"]["currencyIsoCode"],
            };
            items.push(item);
          });
          basketAmount = response["amount"] !== undefined ? response["amount"]["gross"] : 0;
          basketCurrency = response["amount"] !== undefined ? response["amount"]["currencyIsoCode"] : 'PLN';

          callback(itemQuantity, items, basketAmount, basketCurrency);
        }
      } catch (error) {
        throw `Bład w zwrotce z API: ${error}`;
      }
    };

    const body = JSON.stringify({
      cartType: "Standard",
    });

    this._doRequest(
      success,
      failure,
      body,
      "post",
      this.url + "/api/ecommerce/basket/GetPage/"
    );
  }

  addProductToBasket(productData, callback) {
    const success = () => {
      callback();
    };
    const failure = (statusCode, responseText) => {
      console.error(
        `Wystąpił błąd komunikacji z API: ${statusCode} : ${responseText}`
      );
    };

    /*
    to_hash = "".join(
      [
          str(data["quantity"]),
          str(int(data["gross"] * 100)),
          data["currencyIsoCode"],
          settings.WEAVER_PRIVATE_KEY,
      ]
    )
    to_hash = to_hash.lower()
    digest = hashlib.sha256(bytes(to_hash, encoding="utf8")).hexdigest()
    */
    const gross = productData['priceGross'] ? productData['priceGross'] : 0;
    const grossToHash = (gross * 100).toString();
    const toHash = productData['quantity'] + grossToHash + productData["currencyIsoCode"] + this.privateKey;
    const hash = sha256(toHash.toLowerCase());
    let today = new Date().toISOString().slice(0, 10);
    const body = JSON.stringify({
      items: [{
        "offerCode": productData['productCode'] ? productData['productCode'] : productData['packetCode'],
        "quantity": productData['quantity'],
        "startingDate": productData["accessFrom"] ? productData["accessFrom"] : today,
        "startingEmissionId": null,
        "baseGross": 0,
        "gross": gross,
        "currencyIsoCode": productData["currencyIsoCode"],
        "hash": hash
      }],
      cartType: "Standard",
    });

    this._doRequest(
      success,
      failure,
      body,
      "put",
      this.url + "/api/ecommerce/basket/InsertToBasket/"
    );
  }

  changeQuantity(productData, callback) {
    const success = () => {
      callback();
    };
    const failure = (statusCode, responseText) => {
      console.error(
        `Wystąpił błąd komunikacji z API: ${statusCode} : ${responseText}`
      );
    };

    const body = JSON.stringify({
      ...productData,
      cartType: "Standard",
    });

    this._doRequest(
      success,
      failure,
      body,
      "post",
      this.url + "/api/ecommerce/basket/ChangeBasketItemQuantity/"
    );
  }

  _doRequest(success, failure, body, method, endpoint) {
    const xhttp = new XMLHttpRequest();
    xhttp.open(method, endpoint);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authorization-PublicKey", this.publicKey);
    xhttp.setRequestHeader("Authorization-Secret", this._getSecret(endpoint));
    // xhttp.setRequestHeader("Referrer-Policy", "no-referrer-when-downgrade");
    this._setSessionHeader(xhttp);

    xhttp.onload = function () {
      if (this.readyState === 4) {
        if (this.status >= 200 && this.status < 300) {
          success(this.responseText);
        } else {
          failure(this.status, this.responseText);
        }
      }
    };
    xhttp.onerror = function () {
      failure(xhttp.status, xhttp.responseText);
    };

    xhttp.send(body);
  }

  _setSessionHeader(xhttp) {
    const sessionId = this._getSessionId();
    xhttp.setRequestHeader("Authorization-Token", sessionId);
  }

  _getSessionId() {
    const cookieName = "user_session_id";
    return Cookies.get(cookieName) || this._createSessionId(cookieName);
  }

  _createSessionId(cookieName) {
    const cookieTime = 60 * 60 * 24; // 24 godziny
    const sessionId = md5(Date.now().toString());
    Cookies.set(cookieName, sessionId, {
      expires: cookieTime,
      path: "/",
      domain: this.cookieDomain,
      secure: true,
      sameSite: "None",
    });
    return sessionId;
  }

  _getSecret(endpoint) {
    endpoint = endpoint.split("/").filter(element => element != '').slice(-1)[0].toLowerCase()
    const toHash =
      this.privateKey + this.publicKey + this._getSessionId() + endpoint;
    return sha256(toHash.toLowerCase());
  }
}
