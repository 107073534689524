export class ModalConstructor {
  constructor() {
    this.modalId = "basket-modal";
  }

  _createEnterBtn(basketHref) {
    let anchor = document.createElement("a");
    anchor.innerHTML = "Przejdź do koszyka";
    anchor.href = basketHref;
    anchor.referrerPolicy = "no-referrer-when-downgrade";
    anchor.classList.add("modal__button", "modal__button--primary");
    anchor.style.textAlign = "center";
    return anchor;
  }

  _createReturnBtn(modal) {
    let btnReturn = document.createElement("button");
    btnReturn.classList.add("modal__button", "modal__button--secondary");
    btnReturn.innerHTML = "Kontynuuj zakupy";
    let body = document.body;
    btnReturn.onclick = function () {
      body.classList.remove("basketOpen");
      modal.style.display = "none";
    };
    return btnReturn;
  }

  _createBasketModalP() {
    let basketModalP = document.createElement("p");
    basketModalP.classList.add("modal__title");
    basketModalP.innerHTML = "Koszyk";
    return basketModalP;
  }

  _createModalClose(modal) {
    let modalClose = document.createElement("a");
    modalClose.classList.add("modal__close");
    let body = document.body;
    modalClose.onclick = function () {
      body.classList.remove("basketOpen");
      modal.style.display = "none";
    };
    return modalClose;
  }

  _createModalContentDiv(modal) {
    let content = document.createElement("div");
    content.classList.add("modal__content");
    let basketModalP = this._createBasketModalP();
    let modalClose = this._createModalClose(modal);
    content.appendChild(modalClose);
    content.appendChild(basketModalP);
    return content;
  }

  _createModalButtonsDiv(modal, basketHref) {
    let buttons = document.createElement("div");
    buttons.classList.add("modal__buttons");
    let buttonEnter = this._createEnterBtn(basketHref);
    let buttonReturn = this._createReturnBtn(modal);
    buttons.appendChild(buttonEnter);
    buttons.appendChild(buttonReturn);
    return buttons;
  }

  _createSummaryItem(item) {
    let summaryItem = document.createElement("li");
    summaryItem.classList.add("summary__item");

    let summaryDetail = document.createElement("div");
    summaryDetail.classList.add("summary__details");
    let summaryItemP = document.createElement("p");
    summaryItemP.classList.add("summary__product");
    summaryItemP.innerHTML = item.title;
    let divQuantity = document.createElement("div");
    divQuantity.classList.add("summary__quantity");
    divQuantity.innerHTML = "ilość: <span>" + item.quantity + "szt.</span>";

    summaryDetail.appendChild(summaryItemP);
    summaryDetail.appendChild(divQuantity);

    let summaryValue = document.createElement("div");
    summaryValue.classList.add("summary__value");
    let span = document.createElement("span");
    span.innerHTML = this._formatPrice(item.price) + (item.currencyIsoCode === "USD"? " $" : " zł");
    let spanTax = document.createElement("span");
    spanTax.classList.add("summary__tax");
    if (item.currencyIsoCode == "PLN") {
    spanTax.innerHTML = " (z VAT)";
    }
    summaryValue.appendChild(span);
    summaryValue.appendChild(spanTax);

    summaryItem.appendChild(summaryDetail);
    summaryItem.appendChild(summaryValue);
    return summaryItem;
  }

  _createSummaryItems(items) {
    let summaryItems = document.createElement("ul");
    summaryItems.classList.add("summary__list");
    items.forEach((element) => {
      let summaryItem = this._createSummaryItem(element);
      summaryItems.append(summaryItem);
    });
    return summaryItems;
  }

  _createSummaryTotal(basketAmount, basketCurrency) {
    let summaryTotal = document.createElement("div");
    summaryTotal.classList.add("summary__total");
    let span1 = document.createElement("span");
    span1.classList.add("summary__toPay");
    span1.innerHTML = "Do zapłaty: ";
    let span2 = document.createElement("span");
    span2.classList.add("summary__total__sum");
    span2.innerHTML = this._formatPrice(basketAmount) + (basketCurrency === "USD"? " $" : " zł");
    let span3 = document.createElement("span");
    span3.classList.add("summary__tax");
    if (basketCurrency == "PLN") {
    span3.innerHTML = " (z VAT)";
    }
    summaryTotal.appendChild(span1);
    summaryTotal.appendChild(span2);
    summaryTotal.appendChild(span3);
    return summaryTotal;
  }

  _createModalSummary(items, basketAmount, basketCurrency) {
    let summaryDiv = document.createElement("div");
    summaryDiv.classList.add("summary");
    let summaryItems = this._createSummaryItems(items);
    let summaryTotal = this._createSummaryTotal(basketAmount, basketCurrency);
    summaryDiv.appendChild(summaryItems);
    summaryDiv.appendChild(summaryTotal);
    return summaryDiv;
  }

  _formatPrice(price) {
    return price
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  }

  _createModalDiv() {
    let modal = document.createElement("div");
    modal.id = this.modalId;
    modal.classList.add("basket__modal");
    return modal;
  }

  _appendModalContent(modal, basketHref, items, basketAmount, basketCurrency) {
    let modalContent = this._createModalContentDiv(modal);
    let modalSummary = this._createModalSummary(items, basketAmount, basketCurrency);
    let modalButtons = this._createModalButtonsDiv(modal, basketHref);
    modal.appendChild(modalContent);
    modal.appendChild(modalSummary);
    modal.appendChild(modalButtons);
  }

  createModal(basketHref, items, basketAmount, basketCurrency) {
    const modal = this._createModalDiv();
    this._appendModalContent(modal, basketHref, items, basketAmount, basketCurrency);
    modal.style.display = "none";
    return modal;
  }

  showModal() {
    document.getElementById(this.modalId).style.display = "block";
  }

  refreshModal(basketHref, items, basketAmount, basketCurrency) {
    const modal = document.getElementById(this.modalId);
    modal.innerHTML = "";
    this._appendModalContent(modal, basketHref, items, basketAmount, basketCurrency);
  }
}
