import { IconConstructor } from "./iconConstructor.js";
import { CSSConstructor } from "./cssConstructor";
import { ModalConstructor } from "./modalConstructor";
import { API } from "./api";
import { WeaverAPI } from "./api";
import { IndicatorConstructor } from "./indicatorConstructor";
import { IndicatorConstructorCurrencyWarning } from "./indicatorConstructorCurrencyWarning";
import { sha256 } from "js-sha256";

export class Basket {
  constructor(
    currentScript,
    appendId,
    userHref,
    basketHref,
    apiUrl,
    cookieDomain,
    showModal = false,
    showEmptyBasket = false,
    externalWidget = false
  ) {
    this.currentScript = currentScript;
    this.appendId = appendId;
    this.userHref = userHref;
    this.basketHref = basketHref;
    this.containerClass = "basket";
    this.cookieDomain = cookieDomain;
    this.showModal = showModal;
    this.showEmptyBasket = showEmptyBasket;
    this.externalWidget = externalWidget;

    this.weaverApi = new WeaverAPI(
      apiUrl,
      this.cookieDomain
    );
    this.api = this.weaverApi;
    this.modalConstructor = new ModalConstructor();
    this.indicatorConstructor = new IndicatorConstructor(basketHref);
    this.indicatorConstructorCurrencyWarning = new IndicatorConstructorCurrencyWarning(
      basketHref
    );
    this.iconConstructor = new IconConstructor();

    this.state = { items: [] };
  }

  init() {
    this.appendTag = this._getAppendTag();
    if (location.host.startsWith("dev")) {
      this.weaverApi.getItemQuantity(this.setBasket.bind(this));
    } else {
      this.api.getItemQuantity(this.setBasket.bind(this));
    }
  }

  _getAppendTag() {
    const appendTag = document.getElementById(this.appendId);
    if (appendTag === null) {
      throw `Nie znaleziono elementu o id="${this.appendId}". Pamiętaj, że musi się znaleźć na stronie ze skryptem!`;
    }
    return appendTag;
  }

  setBasket(itemQuantity, items, basketAmount, basketCurrency) {
    this._setBasketData(itemQuantity, items, basketAmount, basketCurrency);

    this.container = this._createContainer();
    this.appendTag.append(this.container);
    this._appendCSS(this.appendTag);
    const _this = this;
    _this.addProduct(this.container);

    window.addEventListener("hashchange", () => {
      return _this.addProduct(this.container);
    });
  }

  _createContainer() {
    const basketModal = this.modalConstructor.createModal(
      this.basketHref,
      this.items,
      this.basketAmount,
      this.basketCurrency
    );

    const userIcon = this.iconConstructor.getUserIcon(this.userHref);
    const basketIcon = this.showModal
      ? this.iconConstructor.getBasketIcon(null, this.itemQuantity, basketModal)
      : this.iconConstructor.getBasketIcon(
          this.basketHref,
          this.itemQuantity,
          null
        );
    const indicator = this.indicatorConstructor.getIndicator();
    const indicatorCurrencyWarning = this.indicatorConstructorCurrencyWarning.getIndicator();
    let container = document.createElement("div");
    container.classList.add(this.containerClass);
    container.append(userIcon);
    container.append(basketIcon);
    container.append(basketModal);
    container.append(indicator);
    container.append(indicatorCurrencyWarning);
    if (this.showEmptyBasket) {
      this._hideBasket(container);
    }
    if (this.itemQuantity !== 0 && this.showEmptyBasket) {
      this._showBasket(container);
    }

    return container;
  }

  _hideBasket(container) {
    container.style.display = "none";
  }

  _showBasket(container) {
    container.style.display = "block";
  }

  _appendCSS(appendTag) {
    const constructor = new CSSConstructor();
    const styleTag = constructor.getStyleTag();
    appendTag.after(styleTag);
  }

  addProduct() {
    if (location.hash.startsWith("#basketAdd")) {
      this.indicatorConstructor.show();
      const params = this._getParamsFromHash();
      location.hash = "";
      if (location.host.startsWith("dev")) {
        this.weaverApi.addProductToBasket(
          params,
          this.refreshBasketData.bind(this)
        );
      } else {
        this.api.addProductToBasket(params, this.refreshBasketData.bind(this));
      }
    }
  }

  _getParamsFromHash() {
    if (location.hash.indexOf("?") > -1) {
      const paramsList = location.hash.split("?")[1].split("&");
      const params = {};
      paramsList.forEach(elem => {
        const [paramKey, paramValue] = elem.split("=");
        params[paramKey] = decodeURI(paramValue);
      });
      return params;
    } else {
      return {};
    }
  }

  addOrChangeProductQuantity(productCode, currency, productPrice) {
    const item = this.getItemFromState(productCode);
    const quantity = this.getExternalWidgetQuantity();

    if (item && item.quantity) {
      this.changeQuantityProduct(productCode, item.quantity + quantity);
    } else {
      this.addProductFromCode(productCode, quantity, currency, productPrice);
    }
  }

  addProductFromCode(productCode, quantity = 1, currency, productPrice) {
    if (!!this.state.items.length && this.isNotSameCurrency(currency)) {
      this.indicatorConstructorCurrencyWarning.show();
      return;
    }
    let productData;
    if (productCode.search("-packet") > 0) {
      productCode = productCode.split("-")[0];
      productData = {
        packetCode: productCode,
        quantity: quantity,
        currencyIsoCode: currency
      };
    } else {
      productData = {
        productCode: productCode,
        quantity: quantity,
        currencyIsoCode: currency
      };
    }
    productData.priceGross = Number(productPrice);
    if (productData.hasOwnProperty("productCode")) {
      productData.hash = this._setProductHash(
        currency,
        productData.priceGross,
        productCode,
        quantity
      );
    } else {
      productData.hash = this._setPacketHash(
        currency,
        productCode,
        productData.priceGross,
        quantity
      );
    }
    if (location.host.startsWith("dev")) {
      this.weaverApi.addProductToBasket(
        productData,
        this.refreshBasketData.bind(this)
      );
    } else {
      this.api.addProductToBasket(
        productData,
        this.refreshBasketData.bind(this)
      );
    }
    this.indicatorConstructor.show();
  }

  checkAndUpdateExternalWidgetQuantity() {
    if (this.externalWidget) {
      const productCode = this.getExternalWidgetProductCode();
      const item = this.getItemFromState(productCode);

      if (item && item.quantity > 1) {
        this.setExternalWidgetQuantity(item.quantity);
      }
    }
  }

  getItemFromState(productCode) {
    return this.state.items.find(item => item.product.code == productCode);
  }

  increaseExternalWidgetQuantity() {
    const quantity = this.getExternalWidgetQuantity();

    const newQuantity = quantity + 1;
    this.setExternalWidgetQuantity(newQuantity);
  }

  decreaseExternalWidgetQuantity() {
    const quantity = this.getExternalWidgetQuantity();

    if (quantity === 1) {
      return;
    }

    const newQuantity = quantity - 1;
    this.setExternalWidgetQuantity(newQuantity);
  }

  getExternalWidgetQuantity() {
    return +document.getElementById("product-basket-quantity").textContent;
  }

  getExternalWidgetProductCode() {
    return document.getElementById("product-basket-quantity").dataset
      .productCode;
  }

  setExternalWidgetQuantity(quantity) {
    document.getElementById("product-basket-quantity").textContent = quantity;
  }

  changeQuantityProduct(productCode, quantity) {
    const item = this.getItemFromState(productCode);

    if (location.host.startsWith("dev")) {
      this.weaverApi.changeQuantity(
        { newQuantity: quantity, cartOfferId: item.cartOfferId },
        this.refreshBasketData.bind(this)
      );
    } else {
      this.api.changeQuantity(
        { newQuantity: quantity, basketItemId: item.id },
        this.refreshBasketData.bind(this)
      );
    }

    this.indicatorConstructor.show();
  }

  refreshBasketData() {
    if (location.host.startsWith("dev")) {
      this.weaverApi.getItemQuantity(this.refreshBasket.bind(this));
    } else {
      this.api.getItemQuantity(this.refreshBasket.bind(this));
    }
  }

  refreshBasket(itemQuantity, items, basketAmount) {
    this._setBasketData(itemQuantity, items, basketAmount);

    this.modalConstructor.refreshModal(
      this.basketHref,
      this.items,
      this.basketAmount
    );
    this.iconConstructor.refreshQuantity(this.itemQuantity);
    this._showBasket(this.container);
  }

  _setBasketData(itemQuantity, items, basketAmount, basketCurrency) {
    this.itemQuantity = itemQuantity;
    this.items = items;
    this.state.items = items;
    this.basketAmount = basketAmount;
    this.basketCurrency = basketCurrency;
  }

  _setProductHash(currency, price, productCode, quantity) {
    price = price.toString();
    const privateKey = location.host.startsWith("dev")
      ? this.weaverApi.privateKey
      : this.api.privateKey;
    const toHash = currency + price + productCode + quantity + privateKey;
    return sha256(toHash);
  }

  _setPacketHash(currency, packetCode, price, quantity) {
    price = price.toString();
    const privateKey = location.host.startsWith("dev")
      ? this.weaverApi.privateKey
      : this.api.privateKey;
    const toHash = currency + packetCode + price + quantity + privateKey;
    return sha256(toHash);
  }

  isNotSameCurrency(currency) {
    const findItemCurrency = this.state.items.find(
      item => item.currencyIsoCode == currency
    );
    return !findItemCurrency;
  }
}
