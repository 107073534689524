const css = `
.basket .user-icon, .basket .basket-icon {
    display: inline-block;
}
.basket .basket-icon {
    position: relative;
    margin-right: 8px;
}
.basket-icon__counter {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #3DB5E6;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -7px;
    right: -7px;
}
.counterValue {
    font-size: 10px;
    line-height: 10px;
    color: #fff;
    margin: 0;
}
.basket .basket-icon.active path {
    fill: #3DB5E6;
}
.indicator-overlay {
  display: none;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: rgba(224, 223, 223, 0.6);
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 14px;
}
.indicator {
  position: relative;
  top: -86px;
  width: 270px;
  padding: 16px 12px;
  border-radius: 14px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
  -moz-box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
  box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
}

.indicator__title {
  text-align: center;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 22px;
}

.indicator__subtitle {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 16px;
}

.indicator__select {
  display: flex;
  flex-direction: column;
}
.indicator__back_btn {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #007AFF;
  background-color: #FFFFFF;
}
.indicator-warning {
  position: relative;
  top: -86px;
  width: 270px;
  padding: 16px 12px;
  border-radius: 14px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
  -moz-box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
  box-shadow: 0px 0px 18px 0px rgba(66, 68, 90, 0.2);
}
.indicator-overlay-warning {
  display: none;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: rgba(224, 223, 223, 0.6);
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 14px;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  margin: 10px auto;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000000;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.3s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1.4s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -1.5s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -1.6s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -1.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -1.8s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.3s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.4s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -1.5s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -1.6s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -1.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -1.8s;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
.indicator__btn {
  padding: 10px 0;
  color: var(--mainColor);
  background: none;
  outline: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-size: 17px;
  line-height: 22px;
  transition: all 0.2s;
}

.indicator__btn:hover {
  font-weight: 600;
  text-shadow: 0px 4px 15px #9cc4ef;
}

.indicator__select .indicator__btn:first-child {
  font-weight: 600;
}
`;

export class CSSConstructor {
  getStyleTag() {
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    return style;
  }
}
