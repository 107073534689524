export class IndicatorConstructor {
  constructor(basketHref) {
    this.basketHref = basketHref;
  }

  getIndicator() {
    const buttonBasket = this._createButtonBasket();
    const buttonContinue = this._createButtonContinue();
    const indicatorSelect = this._createSelect(buttonBasket, buttonContinue);
    const indicatorTitle = this._createTitle();
    const indicatorSubtitle = this._createSubtitle();
    const container = this._createContainer(
      indicatorTitle,
      indicatorSubtitle,
      indicatorSelect
    );
    const overlay = this._createOverlay(container);
    this.indicator = overlay;
    return overlay;
  }

  _createOverlay(container) {
    const overlay = document.createElement("div");
    overlay.classList.add("indicator-overlay");
    overlay.id = "basket-indicator";
    overlay.append(container);
    return overlay;
  }

  _createContainer(indicatorTitle, indicatorSubtitle, indicatorSelect) {
    const container = document.createElement("div");
    container.classList.add("indicator");
    container.append(indicatorTitle);
    container.append(indicatorSubtitle);
    container.append(indicatorSelect);
    return container;
  }

  _createSubtitle() {
    const indicatorSubtitle = document.createElement("p");
    indicatorSubtitle.classList.add("indicator__subtitle");
    indicatorSubtitle.innerText = "";
    return indicatorSubtitle;
  }

  _createTitle() {
    const indicatorTitle = document.createElement("h3");
    indicatorTitle.classList.add("indicator__title");
    indicatorTitle.innerText = "Dodano do koszyka";
    return indicatorTitle;
  }

  _createSelect(buttonBasket, buttonContinue) {
    const indicatorSelect = document.createElement("div");
    indicatorSelect.classList.add("indicator__select");
    indicatorSelect.append(buttonBasket);
    indicatorSelect.append(buttonContinue);
    return indicatorSelect;
  }

  _createButtonBasket() {
    const _this = this;
    let anchor = document.createElement("a");
    anchor.innerHTML = "Przejdź do koszyka";
    anchor.href = _this.basketHref;
    anchor.referrerPolicy = "no-referrer-when-downgrade";
    anchor.classList.add("indicator__btn");
    return anchor;
  }

  _createButtonContinue() {
    const button = document.createElement("button");
    button.classList.add("indicator__btn");
    button.innerText = "Kontynuuj zakupy";
    const _this = this;
    button.addEventListener("click", () => {
      _this.hide();
    });
    return button;
  }

  show() {
    this.indicator.style.display = "flex";
  }

  hide() {
    this.indicator.style.display = "none";
  }
}
