export class IconConstructor {
  constructor() {
    this.namespaceURI = "http://www.w3.org/2000/svg";
    this.iconWidth = "25px";
    this.iconHeight = "25px";
    this.iconViewBox = "0 0 25 25";
  }

  getUserIcon(userHref) {
    const userIconPath = `
    M12.505,12.512c2.733,0,4.959-2.435,4.959-5.396c0-2.938-2.217-5.246-4.959-5.246c-2.721,0-4.959,2.354-4.959,5.27
    C7.558,10.089,9.773,12.512,12.505,12.512z M12.505,10.778c-1.676,0-3.111-1.607-3.111-3.64c0-1.998,1.412-3.536,3.111-3.536
    c1.709,0,3.112,1.516,3.112,3.513C15.617,9.147,14.193,10.778,12.505,10.778z M5.285,23.131h14.43c1.906,0,2.813-0.574,2.813-1.836
    c0-3.009-3.801-7.36-10.022-7.36c-6.233,0-10.033,4.352-10.033,7.36C2.472,22.557,3.379,23.131,5.285,23.131z M4.745,21.396
    c-0.299,0-0.425-0.08-0.425-0.32c0-1.883,2.917-5.407,8.186-5.407c5.258,0,8.174,3.524,8.174,5.407c0,0.24-0.115,0.32-0.414,0.32
    H4.745z
    `;
    return this._createIcon("user-icon", userHref, userIconPath);
  }

  getBasketIcon(basketHref, itemsQuantity, basketModal = null) {
    const basketIconPath = `
    M8.806,17.874h12.629c0.422,0,0.79-0.346,0.79-0.811c0-0.467-0.368-0.813-0.79-0.813H8.99c-0.617,0-0.996-0.433-1.093-1.093
    l-0.173-1.136h13.732c1.581,0,2.392-0.975,2.619-2.521l0.866-5.725c0.021-0.141,0.043-0.313,0.043-0.41
    c0-0.52-0.39-0.877-0.985-0.877H6.328L6.122,3.114C6.014,2.281,5.711,1.859,4.607,1.859H0.81c-0.434,0-0.802,0.379-0.802,0.812
    c0,0.444,0.368,0.823,0.802,0.823h3.657l1.732,11.882C6.426,16.911,7.237,17.874,8.806,17.874z M23.146,6.123l-0.77,5.184
    c-0.086,0.67-0.443,1.082-1.082,1.082L7.486,12.399L6.566,6.123H23.146z M9.661,23.134c0.973,0,1.753-0.768,1.753-1.753
    c0-0.975-0.78-1.754-1.753-1.754c-0.985,0-1.764,0.779-1.764,1.754C7.897,22.366,8.676,23.134,9.661,23.134z M19.779,23.134
    c0.984,0,1.764-0.768,1.764-1.753c0-0.975-0.779-1.754-1.764-1.754c-0.974,0-1.765,0.779-1.765,1.754
    C18.015,22.366,18.806,23.134,19.779,23.134z
    `;
    const basket = this._createIcon("basket-icon", basketHref, basketIconPath);
    basket.id = "basket-icon";
    this._appendItemQuantity(basket, itemsQuantity);
    let body = document.body;
    if (basketModal) {
      basket.onclick = function () {
        if (basketModal.style.display === "none") {
          body.classList.add("basketOpen");
          basketModal.style.display = "block";
        } else {
          body.classList.remove("basketOpen");
          basketModal.style.display = "none";
        }
      };
    }
    return basket;
  }

  _createIcon(name, href, path) {
    let icon = document.createElement("a");
    if (href) {
      icon.href = href;
    }
    icon.classList.add(name);
    let basketIcon = this._createSVG(path);
    icon.append(basketIcon);
    return icon;
  }

  _createSVG(path) {
    let svgTag = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgTag.setAttribute("width", this.iconWidth);
    svgTag.setAttribute("height", this.iconHeight);
    svgTag.setAttribute("viewBox", this.iconViewBox);
    svgTag.setAttribute("fill", "none");
    svgTag.setAttribute("version", "1.1");
    svgTag.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgTag.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    svgTag.setAttribute("x", "0px");
    svgTag.setAttribute("y", "0px");
    svgTag.setAttribute("enable-background", "new 0 0 25 25");
    svgTag.setAttribute("xml:space", "preserve");
    svgTag.setAttribute("style", "cursor:pointer");
    let pathTag = this._createPath(path);
    svgTag.append(pathTag);
    return svgTag;
  }

  _createPath(path) {
    let path_tag = document.createElementNS(this.namespaceURI, "path");
    path_tag.setAttribute("d", path);
    path_tag.setAttribute("fill", "black");
    return path_tag;
  }

  _appendItemQuantity(basket, itemsQuantity) {
    if (itemsQuantity !== 0) {
      const itemQuantityContainer = document.createElement("div");
      itemQuantityContainer.classList.add("basket-icon__counter");
      itemQuantityContainer.id = "basket-quantity";
      const itemQuantityNumber = document.createElement("p");
      itemQuantityNumber.classList.add("counterValue");
      itemQuantityNumber.innerText = itemsQuantity;
      itemQuantityContainer.append(itemQuantityNumber);
      basket.append(itemQuantityContainer);
    }
  }

  refreshQuantity(itemQuantity) {
    const basket = document.getElementById("basket-icon");
    const quantity = document.getElementById("basket-quantity");
    if (quantity) {
      quantity.remove();
    }
    this._appendItemQuantity(basket, itemQuantity);
  }
}
