export class IndicatorConstructorCurrencyWarning {
  constructor(basketHref) {
    this.basketHref = basketHref;
  }

  getIndicator() {
    const buttonContinue = this._createButtonContinue();
    const indicatorSelect = this._createSelect(buttonContinue);
    const indicatorTitle = this._createTitle();
    const indicatorSubtitle = this._createSubtitle();
    const container = this._createContainer(
      indicatorTitle,
      indicatorSubtitle,
      indicatorSelect
    );
    const overlay = this._createOverlay(container);
    this.indicatorWarning = overlay;
    return overlay;
  }

  _createOverlay(container) {
    const overlay = document.createElement("div");
    overlay.classList.add("indicator-overlay-warning");
    overlay.id = "basket-indicator-warning";
    overlay.append(container);
    return overlay;
  }

  _createContainer(indicatorTitle, indicatorSubtitle, indicatorSelect) {
    const container = document.createElement("div");
    container.classList.add("indicator-warning");
    container.append(indicatorTitle);
    container.append(indicatorSubtitle);
    container.append(indicatorSelect);
    return container;
  }

  _createSubtitle() {
    const indicatorSubtitle = document.createElement("p");
    indicatorSubtitle.classList.add("indicator__subtitle");
    indicatorSubtitle.innerText = "Nie możesz jednoczesnie kupić produktów z cenami o różnych walutach - kup je w osobnych transakcjach";
    return indicatorSubtitle;
  }

  _createTitle() {
    const indicatorTitle = document.createElement("h3");
    indicatorTitle.classList.add("indicator__title");
    indicatorTitle.innerText = "Uwaga";
    return indicatorTitle;
  }

  _createSelect(buttonContinue) {
    const indicatorSelect = document.createElement("div");
    indicatorSelect.classList.add("indicator__select");
    indicatorSelect.append(buttonContinue);
    return indicatorSelect;
  }

  _createButtonContinue() {
    const button = document.createElement("button");
    button.classList.add("indicator__back_btn");
    button.innerText = "Wróć do koszyka";
    const _this = this;
    button.addEventListener("click", () => {
      _this.hide();
    });
    return button;
  }

  show() {
    this.indicatorWarning.style.display = "flex";
  }

  hide() {
    this.indicatorWarning.style.display = "none";
  }
}
