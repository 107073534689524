import { Basket } from "./basket.js";

window.basket = undefined; // globalny obiekt koszyka

/**
 * Inicjalizacja koszyka
 * @param appendId ID kontenera do którego chcemy wstawić koszyk
 * @param userHref Link do strony użytkownika "moje zamówienia"
 * @param basketHref Link do koszyka
 * @param apiUrl Link do API
 * @param cookieDomain Domena do wstawienia do ciasteczka, tak by działało z głównym koszykiem.
 * @param showModal true: wyświetla modal z koszykiem, false: od razu przenosi na stronę z koszykiem
 * @param showEmptyBasket true: wyświetla pusty koszyk, false: wyświetla koszyk dopiero jak będzie w nim produkt
 * @param externalWidget true: wymaga zewnetrznego widgetu Zakup produktu na stronie
 */
function initBasket(
  appendId = "basket",
  userHref = "https://konto.wiemiwybieram.pl/moje-zamowienia",
  basketHref = "https://koszyk.wiemiwybieram.pl",
  apiUrl = "https://koszyk.murator.pl",
  cookieDomain = ".wiemiwybieram.pl",
  showModal = false,
  showEmptyBasket = false,
  externalWidget = false
) {
  const currentScript = document.currentScript;
  window.basket = new Basket(
    currentScript,
    appendId,
    userHref,
    basketHref,
    apiUrl,
    cookieDomain,
    showModal,
    showEmptyBasket,
    externalWidget
  );
  basket.init();
}

window.initBasket = initBasket;
